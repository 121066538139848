var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.show,"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"}},[_c('v-toolbar',{staticStyle:{"position":"fixed","top":"0px","left":"0px","z-index":"1"},attrs:{"dark":"","fixed":"","color":"indigo darken-4"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" Form Leave ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.OPEN_FORM()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_c('div',{staticClass:"rounded-0 form-leave-app mt-14 mt-sm-16 pt-4 white",staticStyle:{"height":"calc(100vh - 56px)!important","overflow-y":"auto"}},[_c('v-data-table',{staticClass:"rounded-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.table,"item-key":"id","server-items-length":_vm.tableTotal,"page":_vm.tablePage,"items-per-page":_vm.tableLimit,"options":_vm.options,"footer-props":{
        itemsPerPageOptions: [25, 50, 100]
      },"loading":_vm.tableLoading,"loading-text":"Loading... Please wait"},on:{"update:page":function($event){_vm.tablePage=$event},"update:itemsPerPage":function($event){_vm.tableLimit=$event},"update:items-per-page":function($event){_vm.tableLimit=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dateFrom",fn:function(ref){
      var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm.$localDT(item.dateFrom, 'daydate'))+" "),_c('small',[_vm._v("to")]),_vm._v(" "+_vm._s(_vm.$localDT(item.dateTo, 'daydate'))+" ")])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":_vm.BindStatus(item.status).color,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.BindStatus(item.status).text)+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"text-capitalize rounded-pill",attrs:{"depressed":"","small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.OPEN_FORM(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" View ")],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogFormLeaveApp.show),callback:function ($$v) {_vm.$set(_vm.dialogFormLeaveApp, "show", $$v)},expression:"dialogFormLeaveApp.show"}},[_c('v-toolbar',{staticStyle:{"position":"fixed","top":"0px","left":"0px","z-index":"1"},attrs:{"dark":"","color":"indigo darken-4"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.dialogFormLeaveApp.data)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(parseInt(_vm.dialogFormLeaveApp.data) ? 'Update' : 'Create New')+" Form Leave ")]):_vm._e(),_c('v-spacer')],1),(_vm.dialogFormLeaveApp.data)?[_c('FormLeaveApp',{attrs:{"data":_vm.dialogFormLeaveApp.data}})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }