<template>
  <v-dialog
    :value="show"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-toolbar
      dark
      fixed
      color="indigo darken-4"
      style="position: fixed;top:0px;left:0px;z-index:1;"
    >
      <v-btn
        icon
        dark
        @click="$router.go(-1)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        Form Leave
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.prevent="OPEN_FORM()">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-toolbar>
    <div class="rounded-0 form-leave-app mt-14 mt-sm-16 pt-4 white" style="height: calc(100vh - 56px)!important;overflow-y: auto;">
      <v-data-table
        dense
        :headers="headers"
        :items="table"
        item-key="id"
        :server-items-length="tableTotal"
        :page.sync="tablePage"
        :items-per-page.sync="tableLimit"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100]
        }"
        :loading="tableLoading"
        class="rounded-0"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.dateFrom`]="{ item }">
          <div class="">
            {{ $localDT(item.dateFrom, 'daydate') }} <small>to</small> {{ $localDT(item.dateTo, 'daydate') }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="BindStatus(item.status).color" dark small class="my-2">
            {{ BindStatus(item.status).text }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <v-btn depressed class="text-capitalize rounded-pill" small dark @click.prevent="OPEN_FORM(item)">
              <v-icon class="mr-2" small>
                mdi-pencil
              </v-icon>
              View
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogFormLeaveApp.show"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-toolbar
          dark
          color="indigo darken-4"
          style="position: fixed;top:0px;left:0px;z-index:1;"
        >
          <v-btn
            icon
            dark
            @click="$router.go(-1)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="dialogFormLeaveApp.data">
            {{ parseInt(dialogFormLeaveApp.data) ? 'Update' : 'Create New' }} Form Leave
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <template v-if="dialogFormLeaveApp.data">
          <FormLeaveApp :data="dialogFormLeaveApp.data" />
        </template>
      </v-dialog>
    </div>
  </v-dialog>
</template>

<script>
let searchTimeout = null
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogFormLeaveApp: {
      show: false,
      data: null
    },
    dates: [],
    menu: false,
    search: '',
    table: [],
    tableLoading: true,
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    options: {},
    tableQuantity: {
      total_summary_normal: 0,
      total_summary: 0,
      tota: 0,
      total_summary_hpp: 0
    },
    headers: [
      {
        text: 'Date Range',
        align: 'start',
        value: 'dateFrom'
      },
      {
        text: 'Status',
        align: 'end',
        value: 'status'
      },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ]
  }),
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (to) {
          if (parseInt(to.query.formLeaveApp)) {
            this.dialogFormLeaveApp.show = true
          } else {
            this.dialogFormLeaveApp.show = false
          }
        } else {
          this.dialogFormLeaveApp.show = false
        }
        // if (from) {
        //   if (from.query.formLeaveApp) {
        //     this.FORM_LEAVE_GET(this.search)
        //   }
        // }
      }
    },
    show (v) {
      if (v) {
        this.search = ''
        this.FORM_LEAVE_GET(this.search)
      }
    },
    dates () {
      this.FORM_LEAVE_GET(this.search)
    },
    search (q) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.FORM_LEAVE_GET(q)
      }, 300)
    },
    options: {
      handler () {
        this.FORM_LEAVE_GET(this.search)
      },
      deep: true
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  mounted () {
    this.FORM_LEAVE_GET('')
  },
  methods: {
    OPEN_FORM (d) {
      const form = d ? this.$StrToArray(d.form) : null
      this.dialogFormLeaveApp.data = null
      if (d) {
        setTimeout(() => {
          this.dialogFormLeaveApp.data = Object.assign(Object.assign({}, d), {
            form
          })
        }, 100)
      } else {
        setTimeout(() => {
          this.dialogFormLeaveApp.data = Object.assign({}, { id: null })
        }, 100)
      }
      this.$router.push('?formLeave=1&formLeaveApp=1')
    },
    FORM_LEAVE_GET (q) {
      this.tableLoading = true
      const fromTo = this.GetFromTo(this.dates)
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const queries = '?x=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : '')
      this.$store.dispatch('forms/FORM_LEAVE_GET', queries + (q ? '&q=' + (q || '') : '') + (fromTo || ''))
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data || []
            this.tableTotal = parseInt(res.data.data.total) || 0
          }
          this.tableLoading = false
        })
    },
    BindStatus (status) {
      status = parseInt(status)
      if (status === 1) {
        return {
          color: 'orange darken-2',
          text: 'In Process'
        }
      } else if (status === 2) {
        return {
          color: 'blue darken-1',
          text: 'Approved'
        }
      }
      return {
        color: 'grey',
        text: 'Draft'
      }
    },
    GetFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return ('&from=' + fromTo[0] + '&to=' + fromTo[1])
          } else {
            return (this.search, '&from=' + fromTo[1] + '&to=' + fromTo[0])
          }
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
// .form-leave {
// }
</style>
